import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
// import Header from "./components/Header";
// import Footer from "./components/newFooter";

const Home = React.lazy(() => import("./components/App"));
const Aboutus = React.lazy(() => import("./components/Aboutlayout"));
const VisionMission = React.lazy(() => import("./components/VisionMission"));
const QualityPolicy = React.lazy(() => import("./components/QualityPolicy"));
const Magazine = React.lazy(() => import("./components/Magazine"));
const ManagementProfile = React.lazy(() =>
  import("./components/ManagementProfile")
);
const Location = React.lazy(() => import("./components/Location"));
const Programmes = React.lazy(() => import("./components/Programmes"));
const AdmissionEligibility = React.lazy(() =>
  import("./components/AdmissionEligibility")
);
const Placement = React.lazy(() => import("./components/Placement"));
const TamilDepart = React.lazy(() => import("./components/Tamil"));
const TamilFaculty = React.lazy(() => import("./components/TamilFaculty"));
const Mathsdept = React.lazy(() => import("./components/maths/Mathsdept"));
const MathsScope = React.lazy(() => import("./components/maths/MathsScope"));
const MathsClubactivite = React.lazy(() =>
  import("./components/maths/MathsClubactivite")
);
const MathsClubActsingle = React.lazy(() =>
  import("./components/maths/MathsClubActsingle")
);
const MathsEvents = React.lazy(() => import("./components/maths/MathsEvents"));
const EventsPage = React.lazy(() => import("./components/EventsPage"));
const MathsFacultyinfo = React.lazy(() =>
  import("./components/maths/MathsFacultyinfo")
);
const MathsIndustryvist = React.lazy(() =>
  import("./components/maths/MathsIndustryvist")
);
const MathsIndustryvistsingle = React.lazy(() =>
  import("./components/maths/MathsIndustryvistsingle")
);
const IndustryvistiSingle = React.lazy(() =>
  import("./components/IndustryvistiSingle")
);
const Microbiology = React.lazy(() => import("./components/Microbiology"));
const Microbiologyscope = React.lazy(() =>
  import("./components/Microbiologyscope")
);
const Microbiologyfaculty = React.lazy(() =>
  import("./components/Microbiologyfaculty")
);
const Microbiologyevents = React.lazy(() =>
  import("./components/Microbiologyevents")
);
const Microbiologystdachive = React.lazy(() =>
  import("./components/Microbiologystdachive")
);
const MicrobiologyIV = React.lazy(() => import("./components/MicrobiologyIV"));
const MicroIndustryvistsingle = React.lazy(() =>
  import("./components/MicroIndustryvistsingle")
);
const MicroIndustryvistiSingle = React.lazy(() =>
  import("./components/MicroIndustryvistiSingle")
);
const MicrobiologyLab = React.lazy(() =>
  import("./components/MicrobiologyLab")
);
const Chemistry = React.lazy(() => import("./components/chemistry/Chemistry"));
const Chemistryscope = React.lazy(() =>
  import("./components/chemistry/Chemistryscope")
);
const Chemistrystaff = React.lazy(() =>
  import("./components/chemistry/Chemistrystaff")
);
const ChemistryEvents = React.lazy(() =>
  import("./components/chemistry/ChemistryEvents")
);
const ChemistryIV = React.lazy(() =>
  import("./components/chemistry/ChemistryIV")
);
const ChemistryIndustryvistsingle = React.lazy(() =>
  import("./components/chemistry/ChemistryIndustryvistsingle")
);
const ChemistryIndustryvistiSingle = React.lazy(() =>
  import("./components/chemistry/ChemistryIndustryvistiSingle")
);
const Chemistryobjectives = React.lazy(() =>
  import("./components/chemistry/Chemistryobjectives")
);
const ChemistryLab = React.lazy(() =>
  import("./components/chemistry/ChemistryLab")
);
const Bca = React.lazy(() => import("./components/bca/Bca"));

const Feerulesregulations = React.lazy(() =>
  import("./components/Feerulesregulations")
);

const LibraryDepart = React.lazy(() => import("./components/Library"));
const LibraryCollections = React.lazy(() =>
  import("./components/LibraryCollections")
);
const LibrarySections = React.lazy(() =>
  import("./components/LibrarySections")
);
const LibraryServices = React.lazy(() =>
  import("./components/LibraryServices")
);
const LibraryRulesRegulations = React.lazy(() =>
  import("./components/LibraryRulesRegulations")
);
const LibraryMembershipTeam = React.lazy(() =>
  import("./components/LibraryMembershipTeam")
);
const Sports = React.lazy(() => import("./components/Sports"));
const SonacasNSS = React.lazy(() => import("./components/SonacasNSS"));
const SonacasNCC = React.lazy(() => import("./components/ncc/Ncc"));
const NSSVisionMission = React.lazy(() =>
  import("./components/NSSVisionMission")
);
const NssObjectives = React.lazy(() => import("./components/NssObjectives"));
const NssActivities = React.lazy(() => import("./components/NssActivities"));
const Edcell = React.lazy(() => import("./components/Edcell"));
const Edcvission = React.lazy(() => import("./components/Edcvission"));
const Edcobjective = React.lazy(() => import("./components/Edcobjective"));
const EdcAchivements = React.lazy(() => import("./components/EdcAchivements"));
const EdcAchivement1 = React.lazy(() => import("./components/Edcachivement1"));
const EdcActivitie = React.lazy(() => import("./components/EdcActivitie"));
const Chemistryachive = React.lazy(() =>
  import("./components/chemistry/Chemistryachive")
);
const Chemistryclubactivitie = React.lazy(() =>
  import("./components/chemistry/Chemistryclubactivitie")
);

const ClubActSignle = React.lazy(() => import("./components/ClubActSignle"));

const ErrorPage = React.lazy(() => import("./components/ErrorPage"));
const BcaEvents = React.lazy(() => import("./components/bca/BcaEvents"));
const BcaStaff = React.lazy(() => import("./components/bca/BcaStaff"));
const BcaScope = React.lazy(() => import("./components/bca/BcaScope"));
const BcaCourse = React.lazy(() => import("./components/bca/BcaCourse"));
const BcaAhcive = React.lazy(() => import("./components/bca/BcaAhcive"));
const BcaIV = React.lazy(() => import("./components/bca/BcaIV"));
const BcaIndustryvistsingle = React.lazy(() =>
  import("./components/bca/BcaIndustryvistsingle")
);
const BcaIndustryvistiSingle = React.lazy(() =>
  import("./components/bca/BcaIndustryvistiSingle")
);
const BcaPlacement = React.lazy(() => import("./components/bca/BcaPlacement"));
const BcaLab = React.lazy(() => import("./components/bca/BcaLab"));
const BcaPub = React.lazy(() => import("./components/bca/BcaPublications"));
const BcaExtension = React.lazy(() => import("./components/bca/BcaExtension"));
const BcaClub = React.lazy(() => import("./components/bca/BcaClubActivities"));
const BcaRank = React.lazy(() => import("./components/bca/BcaRank"));
const MathsdeptEvents = React.lazy(() =>
  import("./components/maths/MathsdeptEvents")
);
const MicrobioSingleEvents = React.lazy(() =>
  import("./components/MicrobioSingleEvents")
);
const ChemistrySingleEvents = React.lazy(() =>
  import("./components/chemistry/ChemistrySingleEvents")
);
const BcaSingleEvents = React.lazy(() =>
  import("./components/bca/BcaSingleEvents")
);
const Bba = React.lazy(() => import("./components/bba/Bba"));
const BbaScope = React.lazy(() => import("./components/bba/BbaScope"));
const BbaStaff = React.lazy(() => import("./components/bba/BbaStaff"));
const BbaEvents = React.lazy(() => import("./components/bba/BbaEvents"));
const BbaSingleEvents = React.lazy(() =>
  import("./components/bba/BbaSingleEvents")
);
const BbaAhcive = React.lazy(() => import("./components/bba/BbaAhcive"));
const BbaIV = React.lazy(() => import("./components/bba/BbaIV"));
const BbaIndustryvistiSingle = React.lazy(() =>
  import("./components/bba/BbaIndustryvistiSingle")
);
const BbaPlacement = React.lazy(() => import("./components/bba/BbaPlacement"));
const BbaIndustryvistsingle = React.lazy(() =>
  import("./components/bba/BbaIndustryvistsingle")
);
const BioTech = React.lazy(() => import("./components/biotechnology/BioTech"));
const BioTechScope = React.lazy(() =>
  import("./components/biotechnology/BioTechScope")
);
const BioTechStaff = React.lazy(() =>
  import("./components/biotechnology/BioTechStaff")
);
const BioTechEvents = React.lazy(() =>
  import("./components/biotechnology/BioTechEvents")
);
const BioTechSingleEvents = React.lazy(() =>
  import("./components/biotechnology/BioTechSingleEvents")
);
const BioTechAhcive = React.lazy(() =>
  import("./components/biotechnology/BioTechAhcive")
);
const BioTechIV = React.lazy(() =>
  import("./components/biotechnology/BioTechIV")
);
const BioTechIndustryvistsingle = React.lazy(() =>
  import("./components/biotechnology/BioTechIndustryvistsingle")
);
const BioTechIndustryvistisingle = React.lazy(() =>
  import("./components/biotechnology/BioTechIndustryvistiSingle")
);
const BioTechPlacement = React.lazy(() =>
  import("./components/biotechnology/BioTechPlacement")
);
const BioTechLab = React.lazy(() =>
  import("./components/biotechnology/BioTechLab")
);
const CommTech = React.lazy(() => import("./components/commerce/Comm"));
const CommScope = React.lazy(() => import("./components/commerce/CommScope"));
const CommStaff = React.lazy(() => import("./components/commerce/CommStaff"));
const CommEvents = React.lazy(() => import("./components/commerce/CommEvents"));
const CommSingleEvents = React.lazy(() =>
  import("./components/commerce/CommSingleEvents")
);
const CommAhcive = React.lazy(() => import("./components/commerce/CommAhcive"));
const CommIV = React.lazy(() => import("./components/commerce/CommIV"));
const CommIndustryvistsingle = React.lazy(() =>
  import("./components/commerce/CommIndustryvistsingle")
);
const CommIndustryvistisingle = React.lazy(() =>
  import("./components/commerce/CommIndustryvistiSingle")
);
const CommPlacement = React.lazy(() =>
  import("./components/commerce/CommPlacement")
);
const CommLab = React.lazy(() => import("./components/commerce/CommLab"));
const CommUnivRankHolder = React.lazy(() =>
  import("./components/commerce/CommRankHolder")
);
const CommIndTie = React.lazy(() =>
  import("./components/commerce/CommIndustryTieUP")
);
const CSTech = React.lazy(() => import("./components/computerscience/CS"));
const CSScope = React.lazy(() =>
  import("./components/computerscience/CSScope")
);
const CSCourse = React.lazy(() =>
  import("./components/computerscience/CSCourse")
);
const CSStaff = React.lazy(() =>
  import("./components/computerscience/CSStaff")
);
const CSEvents = React.lazy(() =>
  import("./components/computerscience/CSEvents")
);
const CSSingleEvents = React.lazy(() =>
  import("./components/computerscience/CSSingleEvents")
);
const CSAhcive = React.lazy(() =>
  import("./components/computerscience/CSAhcive")
);
const CSIV = React.lazy(() => import("./components/computerscience/CSIV"));
const CSIndustryvistsingle = React.lazy(() =>
  import("./components/computerscience/CSIndustryvistsingle")
);
const CSIndustryvistisingle = React.lazy(() =>
  import("./components/computerscience/CSIndustryvistiSingle")
);
const CSClub = React.lazy(() =>
  import("./components/computerscience/CSClub")
);
const CSPlacement = React.lazy(() =>
  import("./components/computerscience/CSPlacement")
);
const CSRank = React.lazy(() => import("./components/computerscience/CSRank"));
const CSIndTie = React.lazy(() =>
  import("./components/computerscience/CSIndustryTieUP")
);
const CSLab = React.lazy(() => import("./components/computerscience/CSLab"));
const CSExtension = React.lazy(() =>
  import("./components/computerscience/CSExtension")
);
const EnglishScope = React.lazy(() =>
  import("./components/english/EnglishScope")
);
const EnglishStaff = React.lazy(() =>
  import("./components/english/EnglishStaff")
);
const EnglishPublications = React.lazy(() =>
  import("./components/english/EnglishPublications")
);
const EnglishEvents = React.lazy(() =>
  import("./components/english/EnglishEvents")
);
const EnglishSingleEvents = React.lazy(() =>
  import("./components/english/EnglishSingleEvents")
);
const EnglishAhcive = React.lazy(() =>
  import("./components/english/EnglishAhcive")
);
const EnglishIV = React.lazy(() => import("./components/english/EnglishIV"));
const EnglishIndustryvistsingle = React.lazy(() =>
  import("./components/english/EnglishIndustryvistsingle")
);
const EnglishIndustryvistisingle = React.lazy(() =>
  import("./components/english/EnglishIndustryvistiSingle")
);
const EnglishPlacement = React.lazy(() =>
  import("./components/english/EnglishPlacement")
);
const EnglishLab = React.lazy(() => import("./components/english/EnglishLab"));
const EnglishTech = React.lazy(() => import("./components/english/English"));
const EnglishClubactivite = React.lazy(() =>
  import("./components/english/EnglishClubactivite")
);
const Physics = React.lazy(() => import("./components/Physics/Physics"));
const PhysicsScope = React.lazy(() =>
  import("./components/Physics/PhysicsScope")
);
const PhysicsStaff = React.lazy(() =>
  import("./components/Physics/PhysicsStaff")
);
const PhysicsEvents = React.lazy(() =>
  import("./components/Physics/PhysicsEvents")
);
const PhysicsSingleEvents = React.lazy(() =>
  import("./components/Physics/PhysicsSingleEvents")
);
const PhysicsAhcive = React.lazy(() =>
  import("./components/Physics/PhysicsAhcive")
);
const PhysicsIV = React.lazy(() => import("./components/Physics/PhysicsIV"));
const PhysicsIndustryvistisingle = React.lazy(() =>
  import("./components/Physics/PhysicsIndustryvistiSingle")
);
const PhysicsIndustryvistsingle = React.lazy(() =>
  import("./components/Physics/PhysicsIndustryvistsingle")
);
const PhysicsPlacement = React.lazy(() =>
  import("./components/Physics/PhysicsPlacement")
);
const PhysicsClubactivite = React.lazy(() =>
  import("./components/Physics/PhysicsClubactivite")
);
const PhysicsLab = React.lazy(() => import("./components/Physics/PhysicsLab"));
const PhysicsRankHolder = React.lazy(() =>
  import("./components/Physics/PhysicsRankHolder")
);
const Psychology = React.lazy(() =>
  import("./components/Psychology/Psychology")
);
const PsychologyScope = React.lazy(() =>
  import("./components/Psychology/PsychologyScope")
);
const PsychologyStaff = React.lazy(() =>
  import("./components/Psychology/PsychologyStaff")
);
const PsychologyEvents = React.lazy(() =>
  import("./components/Psychology/PsychologyEvents")
);
const PsychologySingleEvents = React.lazy(() =>
  import("./components/Psychology/PsychologySingleEvents")
);
const PsychologyLab = React.lazy(() =>
  import("./components/Psychology/PsychologyLab")
);

const PsychologySingleAchive = React.lazy(() =>
  import("./components/Psychology/PsychologySingleAchive")
);
const PsychologyAhcive = React.lazy(() =>
  import("./components/Psychology/PsychologyAhcive")
);
const PsychologyIV = React.lazy(() =>
  import("./components/Psychology/PsychologyIV")
);
const PsychologyIndustryvistinewsingle = React.lazy(() =>
  import("./components/Psychology/PsychologyIndustryvistinewsingle")
);
const PsychologyIndustryvistsingle = React.lazy(() =>
  import("./components/Psychology/PsychologyIndustryvistsingle")
);
const PsychologyRank = React.lazy(() =>
  import("./components/Psychology/PsychologyRank")
);

const NCCEvents = React.lazy(() => import("./components/ncc/NCCEvents"));
const NCCSingleEvents = React.lazy(() =>
  import("./components/ncc/NCCSingleEvents")
);
const NCCSingleAchive = React.lazy(() =>
  import("./components/ncc/NCCSingleAchive")
);
const NCCAhcive = React.lazy(() => import("./components/ncc/NCCAhcive"));

const Tamil = React.lazy(() => import("./components/Tamil/Tamil"));
const TamilScope = React.lazy(() => import("./components/Tamil/TamilScope"));
const TamilStaff = React.lazy(() => import("./components/Tamil/TamilStaff"));
const TamilEvents = React.lazy(() => import("./components/Tamil/TamilEvents"));
const TamilSingleEvents = React.lazy(() =>
  import("./components/Tamil/TamilSingleEvents")
);
const TamilAhcive = React.lazy(() => import("./components/Tamil/TamilAhcive"));
const TamilIV = React.lazy(() => import("./components/Tamil/TamilIV"));
const TamilIndustryvistisingle = React.lazy(() =>
  import("./components/Tamil/TamilIndustryvistiSingle")
);
const TamilIndustryvistsingle = React.lazy(() =>
  import("./components/Tamil/TamilIndustryvistsingle")
);
const TamilClubactivite = React.lazy(() =>
  import("./components/Tamil/TamilClubactivite")
);
const VsComm = React.lazy(() =>
  import("./components/visualcommunication/VsComm")
);
const VsCommScope = React.lazy(() =>
  import("./components/visualcommunication/VsCommScope")
);
const VsCommStaff = React.lazy(() =>
  import("./components/visualcommunication/VsCommStaff")
);
const VsCommEvents = React.lazy(() =>
  import("./components/visualcommunication/VsCommEvents")
);
const VsCommSingleEvents = React.lazy(() =>
  import("./components/visualcommunication/VsCommSingleEvents")
);
const VsCommAhcive = React.lazy(() =>
  import("./components/visualcommunication/VsCommAhcive")
);
const VsCommIV = React.lazy(() =>
  import("./components/visualcommunication/VsCommIV")
);
const VsCommIndustryvistsingle = React.lazy(() =>
  import("./components/visualcommunication/VsCommIndustryvistsingle")
);
const VsCommIndustryvistisingle = React.lazy(() =>
  import("./components/visualcommunication/VsCommIndustryvistiSingle")
);
const VsCommRankHolder = React.lazy(() =>
  import("./components/visualcommunication/VsCommRankHolder")
);
const VsCommIndustryTieUP = React.lazy(() =>
  import("./components/visualcommunication/VsCommIndustryTieUP")
);
const VsCommClubactivite = React.lazy(() =>
  import("./components/visualcommunication/VsCommClubactivite")
);
const VsCommLab = React.lazy(() =>
  import("./components/visualcommunication/VsCommLab")
);
const VsCommPlacement = React.lazy(() =>
  import("./components/visualcommunication/VsCommPlacement")
);
const BbaClubactivite = React.lazy(() =>
  import("./components/bba/BbaClubactivite")
);
const CommClubactivite = React.lazy(() =>
  import("./components/commerce/CommClubactivite")
);
const MathsPlacement = React.lazy(() =>
  import("./components/maths/MathsPlacement")
);
const BbaMou = React.lazy(() => import("./components/bba/BbaMou"));

const BbaRankholder = React.lazy(() =>
  import("./components/bba/BbaRankholder")
);
const BbaSingleAchive = React.lazy(() =>
  import("./components/bba/BbaSingleAchive")
);
const BioTechSingleAchive = React.lazy(() =>
  import("./components/biotechnology/BioTechSingleAchive")
);
const CommSingleAchive = React.lazy(() =>
  import("./components/commerce/CommSingleAchive")
);
const VsCommSingleAchive = React.lazy(() =>
  import("./components/visualcommunication/VsCommSingleAchive")
);
const PhysicsSingleAchive = React.lazy(() =>
  import("./components/Physics/PhysicsSingleAchive")
);
const TamilSingleAchive = React.lazy(() =>
  import("./components/Tamil/TamilSingleAchive")
);
const EnglishSingleAchive = React.lazy(() =>
  import("./components/english/EnglishSingleAchive")
);
const CSSingleAchive = React.lazy(() =>
  import("./components/computerscience/CSSingleAchive")
);
const BcaSingleAchive = React.lazy(() =>
  import("./components/bca/BcaSingleAchive")
);
const MicrobioSingleAchive = React.lazy(() =>
  import("./components/MicrobioSingleAchive")
);
const MathsSingleSAchive = React.lazy(() =>
  import("./components/maths/MathsSingleSAchive")
);
const MathsStdachive = React.lazy(() =>
  import("./components/maths/MathsStdachive")
);
const MathsuniRank = React.lazy(() =>
  import("./components/maths/MathsuniRank")
);
const Contact = React.lazy(() => import("./components/Contact"));
const Eventsoverall = React.lazy(() => import("./components/Eventsoverall"));
const EventcatePage = React.lazy(() => import("./components/EventcatePage"));
const ChemistrySingleAchive = React.lazy(() =>
  import("./components/chemistry/ChemistrySingleAchive")
);
const OurInstitution = React.lazy(() => import("./components/OurInstitution"));
const Facilities = React.lazy(() => import("./components/Facilities"));
const Hostel = React.lazy(() => import("./components/Hostel"));
const Transport = React.lazy(() => import("./components/Transport"));
const Canteen = React.lazy(() => import("./components/Canteen"));
const ScholarshipSchemes = React.lazy(() =>
  import("./components/ScholarshipSchemes")
);
const Incubationcenter = React.lazy(() =>
  import("./components/Incubationcenter")
);
const OnCampusFacilities = React.lazy(() =>
  import("./components/OnCampusFacilities")
);
const BeyondAcademics = React.lazy(() =>
  import("./components/BeyondAcademics")
);
const BcomCA = React.lazy(() => import("./components/bcomca/BcomCA"));
const BcomCAScope = React.lazy(() => import("./components/bcomca/BcomCAScope"));
const BcomCAStaff = React.lazy(() => import("./components/bcomca/BcomCAStaff"));
const BcomCAEvents = React.lazy(() =>
  import("./components/bcomca/BcomCAEvents")
);
const BcomCASingleEvents = React.lazy(() =>
  import("./components/bcomca/BcomCASingleEvents")
);
const BcomCASingleAchive = React.lazy(() =>
  import("./components/bcomca/BcomCASingleAchive")
);
const BcomCAAhcive = React.lazy(() =>
  import("./components/bcomca/BcomCAAhcive")
);
const BcomCAIV = React.lazy(() => import("./components/bcomca/BcomCAIV"));
const BcomCAIndustryvistsingle = React.lazy(() =>
  import("./components/bcomca/BcomCAIndustryvistsingle")
);
const BcomCAIndustryvistisingle = React.lazy(() =>
  import("./components/bcomca/BcomCAIndustryvistiSingle")
);
const BcomCAPlacement = React.lazy(() =>
  import("./components/bcomca/BcomCAPlacement")
);
const BcomCAClubactivite = React.lazy(() =>
  import("./components/bcomca/BcomCAClubactivite")
);
const BcomAF = React.lazy(() => import("./components/bcomaf/BcomAF"));
const BcomAFScope = React.lazy(() => import("./components/bcomaf/BcomAFScope"));
const BcomAFStaff = React.lazy(() => import("./components/bcomaf/BcomAFStaff"));
const BcomAFEvents = React.lazy(() =>
  import("./components/bcomaf/BcomAFEvents")
);
const BcomAFSingleEvents = React.lazy(() =>
  import("./components/bcomaf/BcomAFSingleEvents")
);
const BcomAFSingleAchive = React.lazy(() =>
  import("./components/bcomaf/BcomAFSingleAchive")
);
const BcomAFAhcive = React.lazy(() =>
  import("./components/bcomaf/BcomAFAhcive")
);
const BcomAFIV = React.lazy(() => import("./components/bcomaf/BcomAFIV"));
const BcomAFIndustryvistsingle = React.lazy(() =>
  import("./components/bcomaf/BcomAFIndustryvistsingle")
);
const BcomAFIndustryvistisingle = React.lazy(() =>
  import("./components/bcomaf/BcomAFIndustryvistiSingle")
);
const BcomAFClubactivite = React.lazy(() =>
  import("./components/bcomaf/BcomAFClubactivite")
);
const BcomAFPlacement = React.lazy(() =>
  import("./components/bcomaf/BcomAFPlacement")
);
const Jobopenings = React.lazy(() => import("./components/jobopenings"));
// import ErrorPage from './components/ErrorPage';
const PsychologyClubActivity = React.lazy(() =>
  import("./components/Psychology/PsychologyClubactivity")
);

const PsychologyPlacement = React.lazy(() =>
  import("./components/Psychology/PsychologyPlacement")
);
const PsychologyExtension = React.lazy(() =>
  import("./components/Psychology/PsychologyExtension")
);
const CheckResults = React.lazy(() =>
  import("./components/examination/ciatest/CheckResults")
);

function App() {
  return (
    <>
      <Suspense
        fallback={<div className="spinnerroateloading textcenter"></div>}
      >
        {/* <Header /> */}
        <div className="App">
          <Routes>
            {/* <Route path="/" element={<Home />} /> */}
            <Route path="/" element={<Home />} errorElement={<ErrorPage />} />

            <Route
              path="/about/our-history"
              element={<Aboutus />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/about/our-institution"
              element={<OurInstitution />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/about/vision-mission"
              element={<VisionMission />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/about/quality-policy"
              element={<QualityPolicy />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/about/management-profile"
              element={<ManagementProfile />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/about/location-sonacas"
              element={<Location />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/about/magazine"
              element={<Magazine />}
              errorElement={<ErrorPage />}
            />

            <Route
              path="/department/psychology/placement"
              element={<PsychologyPlacement />}
              errorElement={<ErrorPage />}
            />

            <Route
              path="/department/psychology/club-activities"
              element={<PsychologyClubActivity />}
              errorElement={<ErrorPage />}
            />

            <Route
              path="/department/psychology/extension-activity"
              element={<PsychologyExtension />}
              errorElement={<ErrorPage />}
            />

            <Route
              path="/admission"
              element={<Programmes />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/admission/courses-offered"
              element={<Programmes />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/admission/procedure"
              element={<AdmissionEligibility />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/library"
              element={<LibraryDepart />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/library/collections"
              element={<LibraryCollections />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/library/sections"
              element={<LibrarySections />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/library/services"
              element={<LibraryServices />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/library/rules-regulations"
              element={<LibraryRulesRegulations />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/library/membership-team-member"
              element={<LibraryMembershipTeam />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/sports"
              element={<Sports />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/contact"
              element={<Contact />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/events"
              element={<Eventsoverall />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/events/:id"
              element={<EventsPage />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/events/category/:id"
              element={<EventcatePage />}
              errorElement={<ErrorPage />}
            />

            <Route
              path="/fee-rules-payment"
              element={<Feerulesregulations />}
              errorElement={<ErrorPage />}
            />

            <Route
              path="/facilities/sonacas-national-cadet-corps"
              element={<SonacasNCC />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/facilities/sonacas-national-cadet-corps/events"
              element={<NCCEvents />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/facilities/sonacas-national-cadet-corps/events/:id"
              element={<NCCSingleEvents />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/facilities/sonacas-national-cadet-corps/achievements/:id"
              element={<NCCSingleAchive />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/facilities/sonacas-national-cadet-corps/:id"
              element={<NCCAhcive />}
              errorElement={<ErrorPage />}
            />

            <Route
              path="/facilities/sonacas-national-service-scheme"
              element={<SonacasNSS />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/facilities/national-service-scheme/vision-mission"
              element={<NSSVisionMission />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/facilities/national-service-scheme/objectives"
              element={<NssObjectives />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/facilities/national-service-scheme/activities"
              element={<NssActivities />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/facilities/entrepreneurship-development-cell"
              element={<Edcell />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/facilities"
              element={<Facilities />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/facilities/hostel"
              element={<Hostel />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/facilities/transport"
              element={<Transport />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/facilities/canteen"
              element={<Canteen />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/facilities/scholarship-schemes"
              element={<ScholarshipSchemes />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/facilities/incubation-center"
              element={<Incubationcenter />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/facilities/campus-facilities"
              element={<OnCampusFacilities />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/facilities/beyond-academics"
              element={<BeyondAcademics />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/facilities/entrepreneurship-development-cell/vision-mission"
              element={<Edcvission />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/facilities/entrepreneurship-development-cell/objectives"
              element={<Edcobjective />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/facilities/entrepreneurship-development-cell/achievements"
              element={<EdcAchivements />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/facilities/entrepreneurship-development-cell/achievements/:id"
              element={<EdcAchivement1 />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/facilities/entrepreneurship-development-cell/activity"
              element={<EdcActivitie />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/placement"
              element={<Placement />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/tamil"
              element={<TamilDepart />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/tamil/faculty-info"
              element={<TamilFaculty />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/mathematics"
              element={<Mathsdept />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/mathematics/scope"
              element={<MathsScope />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/mathematics/club-activities"
              element={<MathsClubactivite />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/mathematics/club-activities/:id"
              element={<MathsClubActsingle />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/mathematics/events"
              element={<MathsEvents />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/mathematics/events/:id"
              element={<MathsdeptEvents />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/mathematics/:id"
              element={<MathsStdachive />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/mathematics/achievements/:id"
              element={<MathsSingleSAchive />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/event/:id"
              element={<EventsPage />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/mathematics/faculty-information"
              element={<MathsFacultyinfo />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/mathematics/industrial-visit"
              element={<MathsIndustryvist />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/mathematics/industrial-visit/:id"
              element={<MathsIndustryvistsingle />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/mathematics/industrial-visits/:id"
              element={<IndustryvistiSingle />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/mathematics/placement-details"
              element={<MathsPlacement />}
              errorElement={<ErrorPage />}
            />

            <Route
              path="/department/mathematics/university-rank-holders"
              element={<MathsuniRank />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/microbiology"
              element={<Microbiology />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/microbiology/scope"
              element={<Microbiologyscope />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/microbiology/faculty-information"
              element={<Microbiologyfaculty />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/microbiology/events"
              element={<Microbiologyevents />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/microbiology/events/:id"
              element={<MicrobioSingleEvents />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/microbiology/achievements/:id"
              element={<MicrobioSingleAchive />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/microbiology/:id"
              element={<Microbiologystdachive />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/microbiology/industrial-visit"
              element={<MicrobiologyIV />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/microbiology/industrial-visit/:id"
              element={<MicroIndustryvistsingle />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/microbiology/industrial-visits/:id"
              element={<MicroIndustryvistiSingle />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/microbiology/lab-facilities"
              element={<MicrobiologyLab />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/chemistry"
              element={<Chemistry />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/chemistry/objectives"
              element={<Chemistryobjectives />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/chemistry/scope"
              element={<Chemistryscope />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/chemistry/faculty-information"
              element={<Chemistrystaff />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/chemistry/events"
              element={<ChemistryEvents />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/chemistry/events/:id"
              element={<ChemistrySingleEvents />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/chemistry/:id"
              element={<Chemistryachive />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/chemistry/achievements/:id"
              element={<ChemistrySingleAchive />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/chemistry/industrial-visit"
              element={<ChemistryIV />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/chemistry/industrial-visit/:id"
              element={<ChemistryIndustryvistsingle />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/chemistry/industrial-visits/:id"
              element={<ChemistryIndustryvistiSingle />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/chemistry/club-activities/"
              element={<Chemistryclubactivitie />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/chemistry/lab-facilities/"
              element={<ChemistryLab />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/computer-application/"
              element={<Bca />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/computer-application/scope"
              element={<BcaScope />}
              errorElement={<ErrorPage />}
            />
            {/* <Route
              path="/department/computer-application/courses-offered"
              element={<BcaCourse />}
              errorElement={<ErrorPage />}
            /> */}
            <Route
              path="/department/computer-application/faculty-information"
              element={<BcaStaff />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/computer-application/events"
              element={<BcaEvents />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/computer-application/events/:id"
              element={<BcaSingleEvents />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/computer-application/achievements/:id"
              element={<BcaSingleAchive />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/computer-application/:id"
              element={<BcaAhcive />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/computer-application/industrial-visit/"
              element={<BcaIV />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/computer-application/industrial-visit/:id"
              element={<BcaIndustryvistsingle />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/computer-application/industrial-visits/:id"
              element={<BcaIndustryvistiSingle />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/computer-application/placement-details/"
              element={<BcaPlacement />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/computer-application/lab-facilities/"
              element={<BcaLab />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/computer-application/publications/"
              element={<BcaPub />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/computer-application/extension-activities/"
              element={<BcaExtension />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/computer-application/club-activities/"
              element={<BcaClub />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/computer-application/rank-holders/"
              element={<BcaRank />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/business-administration/"
              element={<Bba />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/business-administration/scope"
              element={<BbaScope />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/business-administration/faculty-information"
              element={<BbaStaff />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/business-administration/events"
              element={<BbaEvents />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/business-administration/events/:id"
              element={<BbaSingleEvents />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/business-administration/achievements/:id"
              element={<BbaSingleAchive />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/business-administration/:id"
              element={<BbaAhcive />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/business-administration/industrial-visit/"
              element={<BbaIV />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/business-administration/industrial-visit/:id"
              element={<BbaIndustryvistsingle />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/business-administration/industrial-visits/:id"
              element={<BbaIndustryvistiSingle />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/business-administration/placement-details/"
              element={<BbaPlacement />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/business-administration/club-activities/"
              element={<BbaClubactivite />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/business-administration/industry-tie"
              element={<BbaMou />}
              errorElement={<ErrorPage />}
            />

            <Route
              path="/department/business-administration/rank-holders"
              element={<BbaRankholder />}
              errorElement={<ErrorPage />}
            />

            <Route
              path="/department/biotechnology/"
              element={<BioTech />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/biotechnology/scope"
              element={<BioTechScope />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/biotechnology/faculty-information"
              element={<BioTechStaff />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/biotechnology/events"
              element={<BioTechEvents />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/biotechnology/events/:id"
              element={<BioTechSingleEvents />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/biotechnology/achievements/:id"
              element={<BioTechSingleAchive />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/biotechnology/:id"
              element={<BioTechAhcive />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/biotechnology/industrial-visit/"
              element={<BioTechIV />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/biotechnology/industrial-visit/:id"
              element={<BioTechIndustryvistsingle />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/biotechnology/industrial-visits/:id"
              element={<BioTechIndustryvistisingle />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/biotechnology/placement-details/"
              element={<BioTechPlacement />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/biotechnology/lab-facilities/"
              element={<BioTechLab />}
              errorElement={<ErrorPage />}
            />
            {/* // Commerse */}
            <Route
              path="/department/commerce/"
              element={<CommTech />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/commerce/scope"
              element={<CommScope />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/commerce/faculty-information"
              element={<CommStaff />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/commerce/events"
              element={<CommEvents />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/commerce/events/:id"
              element={<CommSingleEvents />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/commerce/achievements/:id"
              element={<CommSingleAchive />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/commerce/:id"
              element={<CommAhcive />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/commerce/industrial-visit/"
              element={<CommIV />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/commerce/industrial-visit/:id"
              element={<CommIndustryvistsingle />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/commerce/industrial-visits/:id"
              element={<CommIndustryvistisingle />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/commerce/placement-details/"
              element={<CommPlacement />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/commerce/university-rank-holder/"
              element={<CommUnivRankHolder />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/commerce/industry-tie-ups/"
              element={<CommIndTie />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/commerce/lab-facilities/"
              element={<CommLab />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/commerce/club-activities/"
              element={<CommClubactivite />}
              errorElement={<ErrorPage />}
            />
            {/* // bcom ca */}
            <Route
              path="/department/commerce-ca/"
              element={<BcomCA />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/commerce-ca/scope"
              element={<BcomCAScope />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/commerce-ca/faculty-information"
              element={<BcomCAStaff />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/commerce-ca/events"
              element={<BcomCAEvents />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/commerce-ca/events/:id"
              element={<BcomCASingleEvents />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/commerce-ca/achievements/:id"
              element={<BcomCASingleAchive />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/commerce-ca/:id"
              element={<BcomCAAhcive />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/commerce-ca/industrial-visit/"
              element={<BcomCAIV />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/commerce-ca/industrial-visit/:id"
              element={<BcomCAIndustryvistsingle />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/commerce-ca/industrial-visits/:id"
              element={<BcomCAIndustryvistisingle />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/commerce-ca/placement/"
              element={<BcomCAPlacement />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/commerce-ca/club-activities/"
              element={<BcomCAClubactivite />}
              errorElement={<ErrorPage />}
            />
            {/* // bcom af */}
            <Route
              path="/department/accounting-finance/"
              element={<BcomAF />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/accounting-finance/scope"
              element={<BcomAFScope />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/accounting-finance/faculty-information"
              element={<BcomAFStaff />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/accounting-finance/events"
              element={<BcomAFEvents />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/accounting-finance/events/:id"
              element={<BcomAFSingleEvents />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/accounting-finance/achievements/:id"
              element={<BcomAFSingleAchive />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/accounting-finance/:id"
              element={<BcomAFAhcive />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/accounting-finance/industrial-visit/"
              element={<BcomAFIV />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/accounting-finance/industrial-visit/:id"
              element={<BcomAFIndustryvistsingle />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/accounting-finance/industrial-visits/:id"
              element={<BcomAFIndustryvistisingle />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/accounting-finance/club-activitie/"
              element={<BcomAFClubactivite />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/accounting-finance/placement-details/"
              element={<BcomAFPlacement />}
              errorElement={<ErrorPage />}
            />
            {/* // Com Sci */}
            <Route
              path="/department/computer-science/"
              element={<CSTech />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/computer-science/scope"
              element={<CSScope />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/computer-science/courses-offered"
              element={<CSCourse />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/computer-science/faculty-information"
              element={<CSStaff />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/computer-science/events"
              element={<CSEvents />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/computer-science/events/:id"
              element={<CSSingleEvents />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/computer-science/achievements/:id"
              element={<CSSingleAchive />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/computer-science/:id"
              element={<CSAhcive />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/computer-science/industrial-visit/"
              element={<CSIV />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/computer-science/industrial-visit/:id"
              element={<CSIndustryvistsingle />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/computer-science/industrial-visits/:id"
              element={<CSIndustryvistisingle />}
              errorElement={<ErrorPage />}
            />
             <Route
              path="/department/computer-science/club-activities"
              element={<CSClub />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/computer-science/placement-details/"
              element={<CSPlacement />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/computer-science/rank-holders/"
              element={<CSRank />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/computer-science/industry-tie-ups/"
              element={<CSIndTie />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/computer-science/lab-facilities/"
              element={<CSLab />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/computer-science/extension-activities/"
              element={<CSExtension />}
              errorElement={<ErrorPage />}
            />
            {/* // English */}
            <Route
              path="/department/english/"
              element={<EnglishTech />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/english/scope"
              element={<EnglishScope />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/english/faculty-information"
              element={<EnglishStaff />}
              errorElement={<ErrorPage />}
            />

            <Route
              path="/department/english/publications"
              element={<EnglishPublications />}
              errorElement={<ErrorPage />}
            />

            <Route
              path="/department/english/events"
              element={<EnglishEvents />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/english/events/:id"
              element={<EnglishSingleEvents />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/english/achievements/:id"
              element={<EnglishSingleAchive />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/english/:id"
              element={<EnglishAhcive />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/english/industrial-visit/"
              element={<EnglishIV />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/english/industrial-visit/:id"
              element={<EnglishIndustryvistsingle />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/english/industrial-visits/:id"
              element={<EnglishIndustryvistisingle />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/english/placement-details/"
              element={<EnglishPlacement />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/english/club-activitie/"
              element={<EnglishClubactivite />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/english/lab-facilities/"
              element={<EnglishLab />}
              errorElement={<ErrorPage />}
            />
            {/* // Physics */}
            <Route
              path="/department/physics/"
              element={<Physics />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/physics/scope"
              element={<PhysicsScope />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/physics/faculty-information"
              element={<PhysicsStaff />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/physics/events"
              element={<PhysicsEvents />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/physics/events/:id"
              element={<PhysicsSingleEvents />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/physics/achievements/:id"
              element={<PhysicsSingleAchive />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/physics/:id"
              element={<PhysicsAhcive />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/physics/industrial-visit/"
              element={<PhysicsIV />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/physics/industrial-visit/:id"
              element={<PhysicsIndustryvistsingle />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/physics/industrial-visits/:id"
              element={<PhysicsIndustryvistisingle />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/physics/placement-details/"
              element={<PhysicsPlacement />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/physics/club-activities/"
              element={<PhysicsClubactivite />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/physics/lab-facilities/"
              element={<PhysicsLab />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/physics/university-rank-holders/"
              element={<PhysicsRankHolder />}
              errorElement={<ErrorPage />}
            />

            {/* // Psychology */}
            <Route
              path="/department/psychology/"
              element={<Psychology />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/psychology/scope"
              element={<PsychologyScope />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/psychology/faculty-information"
              element={<PsychologyStaff />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/psychology/events"
              element={<PsychologyEvents />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/psychology/events/:id"
              element={<PsychologySingleEvents />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/psychology/lab-facilities/"
              element={<PsychologyLab />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/psychology/achievements/:id"
              element={<PsychologySingleAchive />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/psychology/:id"
              element={<PsychologyAhcive />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/psychology/industrial-visit/"
              element={<PsychologyIV />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/psychology/industrial-visit/:id"
              element={<PsychologyIndustryvistinewsingle />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/psychology/industrial-visits/:id"
              element={<PsychologyIndustryvistsingle />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/psychology/rank-holders"
              element={<PsychologyRank />}
              errorElement={<ErrorPage />}
            />

            {/* // Tamil */}
            <Route
              path="/department/tamil/"
              element={<Tamil />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/tamil/scope"
              element={<TamilScope />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/tamil/faculty-information"
              element={<TamilStaff />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/tamil/events"
              element={<TamilEvents />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/tamil/events/:id"
              element={<TamilSingleEvents />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/tamil/achievements/:id"
              element={<TamilSingleAchive />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/tamil/:id"
              element={<TamilAhcive />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/tamil/industrial-visit/"
              element={<TamilIV />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/tamil/industrial-visit/:id"
              element={<TamilIndustryvistisingle />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/tamil/industrial-visits/:id"
              element={<TamilIndustryvistsingle />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/tamil/club-activities/"
              element={<TamilClubactivite />}
              errorElement={<ErrorPage />}
            />
            {/* // VsComm */}
            <Route
              path="/department/visual-communication/"
              element={<VsComm />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/visual-communication/scope"
              element={<VsCommScope />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/visual-communication/faculty-information"
              element={<VsCommStaff />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/visual-communication/events"
              element={<VsCommEvents />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/visual-communication/events/:id"
              element={<VsCommSingleEvents />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/visual-communication/achievements/:id"
              element={<VsCommSingleAchive />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/visual-communication/:id"
              element={<VsCommAhcive />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/visual-communication/industrial-visit/"
              element={<VsCommIV />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/visual-communication/industrial-visit/:id"
              element={<VsCommIndustryvistsingle />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/visual-communication/industrial-visits/:id"
              element={<VsCommIndustryvistisingle />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/visual-communication/placement-details/"
              element={<VsCommPlacement />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/visual-communication/university-rank-holders/"
              element={<VsCommRankHolder />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/visual-communication/industry-tie-ups/"
              element={<VsCommIndustryTieUP />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/visual-communication/club-activities/"
              element={<VsCommClubactivite />}
              errorElement={<ErrorPage />}
            />
            <Route
              path="/department/visual-communication/lab-facilities/"
              element={<VsCommLab />}
              errorElement={<ErrorPage />}
            />
           
            <Route
              path="/students-continuous-internal-assessment-test-results/"
              element={<CheckResults />}
            />
          </Routes>
        </div>
        {/* <Footer /> */}
      </Suspense>
    </>
  );
}

export default App;
